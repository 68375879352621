// Migrated
<template lang="pug">
component(
  :is="lottiePlayerIs"
  :src="src",
  :autoplay="autoplay",
  :loop="loop",
  :style="{ width: width + 'px' }"
)
</template>

<script>
import solresorLoading from '@layers/web/assets/animations/solresor-logo.json'

const cdnScript = 'https://unpkg.com/@lottiefiles/lottie-player@1.5.7/dist/lottie-player.js'

export default defineNuxtComponent({
  props: {
    name: {
      type: String,
      default: 'solresorLoading',
    },

    loop: {
      type: Boolean,
      default: true,
    },

    autoplay: {
      type: Boolean,
      default: true,
    },

    width: {
      type: Number,
      default: 200,
    },
  },

  data () {
    return {
      lottiePlayerIs: 'div',
      animations: {
        solresorLoading: '',
      },
    }
  },

  computed: {
    src () {
      return this.animations?.[this.name] || this.animations.solresorLoading
    },
  },

  mounted () {
    loadScript(cdnScript, 'lottie-player-src', this.loaded)
  },

  beforeUnmount () {
    this.lottiePlayerIs = 'div'
  },

  methods: {
    loaded () {
      if (!this.animations.solresorLoading) {
        this.animations.solresorLoading = JSON.stringify(solresorLoading)
      }
      this.lottiePlayerIs = 'lottie-player'
    },
  },
})
</script>
